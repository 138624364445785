<template>
  <div
    class="
      result-wraper
      w-full
      flex flex-wrap
      justify-center
      overflow-hidden
      h-full
    "
  >
    <loader v-if="showLoader" />
    <div
      class="w-full max-h-9/10-screen flex flex-wrap items-start justify-center"
    >
      <div class="w-full">
        <div
          class="
            container
            w-full
            flex flex-wrap
            justify-center
            items-center
            relative
          "
        >
          <h1
            class="
              t-title-2
              text-3xl
              w-auto
              leading-7
              py-3
              md:py-0
              md:leading-10
              mb-0
              text-center
              mx-0
              my-0
              px-10
            "
          >
            {{ loggedIn ? "Detalles de tu cuenta" : "Creación de Cuenta" }}
          </h1>
        </div>
      </div>
      <div
        class="h-vh-8/10 overflow-hidden flex flex-wrap w-full px-5 pb-20 mb-20 mt-5"
        id="scroll-wraper-content"
      >
        <form
          @submit.prevent="onSubmit"
          class="container mx-auto w-full flex flex-wrap pb-20"
        >
          <div class="relative">
              <vue-recaptcha ref="recaptcha" size="invisible" @verify="onVerify" @expired="onExpired" :sitekey="siteKey"></vue-recaptcha>
          </div>        
          <div class="w-full container flex flex-wrap z-0 my-5">
            <div
              v-if="!loggedIn"
              class="t-alert success"
            >
              Ahora crearemos una cuenta para ti, así podrás acceder a tus
              pólizas, administrarlas y recuperarlas. Si ya tienes una, puedes
              acceder a ella dando
              <a
                class="
                  text-primary-dark
                  font-sans font-normal
                  border-b
                  transition-all
                  transform
                  duration-300
                  hover:scale-110
                  hover:border-b-2
                  cursor-default
                "
                @click="setIsUserSideBarOpen({ status: true, timeout: 500 })"
              >
                click aquí
              </a>
              .
            </div>
            <div
              v-else
              class="t-alert success"
            >
              Los datos a continuación son tuyos, puedes modificarlos si es necesario, ocuparémos esta información más adelante. Por favor mantén actualizada esta información.
            </div>
            <!-- <h2 class="my-5 text-4xl">
            Datos del asegurado
          </h2> -->
          </div>
          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Datos de cuenta</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.email && !$v.formData.email.$invalid || !$v.formData.email
                        )
                      "
                      :text="'Email'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-if="!loggedIn"
                        v-model="formData.email"
                        type="email"
                        placeholder="Correo electrónico"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.email.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        v-else
                        class="w-100 text-left t-form cursor-not-allowed"
                      >
                        {{ formData.email }}
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          $v.formData.email &&
                          !$v.formData.email.required
                        "
                      >
                        Email requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          $v.formData.email &&
                          !$v.formData.email.email
                        "
                      >
                        El email no tiene un formato valido:
                        'tucorreo@email.algo'
                      </div>
                    </div>
                  </div>

                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.email_c && !$v.formData.email_c.$invalid
                        )
                      "
                      :text="'Repetir email'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.email_c"
                        type="email"
                        placeholder="Nuevamente tu email"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.email_c.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.email_c.required"
                      >
                        Es necesaria la confirmación de tu email.
                      </div>
                      <div
                        class="t-form-error"
                        v-else-if="
                          showErrors && !$v.formData.email_c.sameAsEmail
                        "
                      >
                        El email debe ser igual al anterior.
                      </div>
                    </div>
                  </div>
                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.password &&
                            !$v.formData.password.$invalid
                        )
                      "
                      :text="'Contraseña'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.password"
                        type="password"
                        placeholder="Contraseña"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.password.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.required"
                      >
                        Debes elegir una contraseña de almenos 5
                        <br />carácteres y al menos un símbolo.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.minLength"
                      >
                        La contraseña debe tener al menos
                        {{
                          $v.formData.password.$params.minLength.min
                        }}
                        carácteres.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password.hasSymbol"
                      >
                        La contraseña debe tener al menos un símbolo.
                      </div>
                    </div>
                  </div>
                  <div v-if="!loggedIn" class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.password_c &&
                            !$v.formData.password_c.$invalid
                        )
                      "
                      :text="'Repetir Contraseña'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model="formData.password_c"
                        type="password"
                        placeholder="Repetir contraseña"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.password_c.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.password_c.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors && !$v.formData.password_c.sameAsPassword
                        "
                      >
                        La contraseña debe ser igual a la anterior.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Datos personales</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean($v.formData.name && !$v.formData.name.$invalid)
                      "
                      :text="'Nombre'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-model.trim="$v.formData.name.$model"
                        type="text"
                        placeholder="Nombre"
                        class="t-form"
                        :class="[$v.formData.name.$invalid ? 'invalid' : '']"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.minLength"
                      >
                        El nombre debe tener al menos
                        {{ $v.formData.name.$params.minLength.min }} carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.name.maxLength"
                      >
                        El nombre debe tener menos de
                        {{ $v.formData.name.$params.maxLength.max }} carácteres
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.first_name &&
                            !$v.formData.first_name.$invalid
                        )
                      "
                      :text="'A. Paterno'"
                      :required="true"
                    />
                    <div class="flex-1 max-w-full">
                      <input
                        v-model.trim="$v.formData.first_name.$model"
                        type="text"
                        placeholder="Apellido paterno"
                        class="t-form"
                        :class="[
                          $v.formData.first_name.$invalid ? 'invalid' : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.minLength"
                      >
                        El apellido debe tener al menos
                        {{ $v.formData.first_name.$params.minLength.min }}
                        carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.first_name.maxLength"
                      >
                        El apellido debe tener menos de
                        {{ $v.formData.first_name.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div>
                  </div>

                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.last_name &&
                            !$v.formData.last_name.$invalid
                        )
                      "
                      :text="'A. Materno'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.last_name.$model"
                        type="text"
                        placeholder="Apellido materno"
                        class="t-form"
                        :class="[
                          $v.formData.last_name.$invalid ? 'invalid' : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.minLength"
                      >
                        El apellido debe tener al menos
                        {{ $v.formData.last_name.$params.minLength.min }}
                        carácteres
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.last_name.maxLength"
                      >
                        El apellido debe tener menos de
                        {{ $v.formData.last_name.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.gender && !$v.formData.gender.$invalid
                        )
                      "
                      :text="'Sexo'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.gender.$model"
                        :options="formDataOptions.gender"
                        index="id"
                        label="name"
                        return-object
                        :class="[
                          showErrors && $v.formData.gender.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      >
                      </v-select>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.gender.id.required"
                      >
                        Requerido.
                      </div>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.civil_status &&
                            !$v.formData.civil_status.$invalid
                        )
                      "
                      :text="'Estado civil'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.civil_status.$model"
                        :options="formDataOptions.civil_status"
                        index="id"
                        label="name"
                        return-object
                        :class="[
                          showErrors && $v.formData.civil_status.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      >
                      </v-select>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors && !$v.formData.civil_status.id.required
                        "
                      >
                        Requerido.
                      </div>
                    </div>
                  </div>





                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean($v.formData.rfc && !$v.formData.rfc.$invalid)
                      "
                      :text="'RFC'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.rfc.$model"
                        @input="
                          $v.formData.rfc.$model = String(
                            $v.formData.rfc.$model
                          ).toUpperCase()
                        "
                        type="text"
                        maxlength="10"
                        placeholder="10 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.rfc.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.rfc"
                      >
                        No es un RFC válido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.rfc.isValidDt"
                      >
                        Tu fecha del RFC debe ser igual a tu fecha de nacimiento
                      </div>

                    </div>
                  </div>





                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.homoclave &&
                            !$v.formData.homoclave.$invalid
                        )
                      "
                      :text="'Homoclave'"
                      :required="true"
                    />
                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.homoclave.$model"
                        @input="
                          $v.formData.homoclave.$model = String(
                            $v.formData.homoclave.$model
                          ).toUpperCase()
                        "
                        type="text"
                        maxlength="3"
                        placeholder="3 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.homoclave.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.homoclave.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="
                          showErrors &&
                          (!$v.formData.homoclave.maxLength ||
                            !$v.formData.homoclave.minLength)
                        "
                      >
                        La homoclave tiene 3 carácteres.
                      </div>
                    </div>
                  </div>
                  





                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.born_date &&
                            !$v.formData.born_date.$invalid
                        )
                      "
                      :text="'Fecha de nacimiento'"
                      :required="true"
                    />

                    <div class="flex-1 relative">
                      <input
                        v-model.trim="userData.born_date"
                        type="date"
                        maxlength="3"
                        placeholder="3 carácteres alfanumericos"
                        class="t-form"
                        :class="[
                          showErrors && $v.formData.born_date.$invalid
                            ? 'invalid'
                            : '',
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.born_date.required"
                      >
                        Requerido.
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.born_date.maxValue"
                      >
                        La fecha no puede ser futura
                      </div>
                    </div>
                  </div>



                  <div class="t-input-group">
                    <t-label
                      class="t-label"
                      :showStatus="showErrors"
                      :valid="
                        Boolean(
                          $v.formData.phone_number && !$v.formData.phone_number.$invalid || !$v.formData.phone_number
                        )
                      "
                      :text="'Teléfono'"
                      :required="true"
                    />

                    <div class="flex-1 relative">
                      <input
                        v-model.trim="$v.formData.phone_number.$model"
                        type="text"
                        placeholder="Teléfono"
                        class="t-form"
                        maxlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"    
                        :class="[
                        $v.formData.phone_number.$invalid && showErrors
                          ? 'invalid'
                          : ''
                        ]"
                      />
                      <div
                        class="t-form-error"
                        v-if="!$v.formData.phone_number.required && showErrors"
                      >
                        Número teléfonico requerido
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Dirección</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <label for="name" class="t-label">C.P.</label>
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.zip_code"
                        :options="formDataOptions.zip_codes"
                        @input="refreshAddressInfo"
                        index="id"
                        label="name"
                        @search="onSearchCP"
                        return-object
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">País</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.country"
                        :options="[{ id: 1, name: 'México' }]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Estado</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.state"
                        :options="[formData.state]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Municipio</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.town_council"
                        :options="[formData.town_council]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Colonia</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="formData.colony"
                        :options="[formData.colony]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="w-full flex flex-wrap">
                    <div
                      class="
                        w-6/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">Calle</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="formData.street"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.formData.street.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <div
                      class="
                        w-3/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">#Ext</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="formData.num_ext"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.formData.num_ext.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <div
                      class="
                        w-3/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">#Int</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="formData.num_int"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.formData.num_int.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <!-- <div class="w-full">
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.num_ext.required"
                      >
                        Número exterior requerido
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.formData.street.maxLength"
                      >
                        Máximo
                        {{ $v.formData.street.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>






          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Facturación</h2>
                <div class="t-section-content py-5">


                  <div class="t-input-group">
                    <label for="name" class="t-label">¿Requieres Factura?</label>
                    
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model.trim="$v.formData.require_billing.$model"
                        :options="['Sí','No']"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>

                  <!--? Billing Data -->

                  <div v-if="formData.require_billing == 'Sí'"
                    class="container flex flex-1 justify-center flex-wrap"
                  >
                    <div class="w-full my-5 text-2xl text-center text-primary">
                      <h1 style="text-transform:uppercase;">Datos de Facturación</h1>
                      <h1 style="font-size:15px;" class="">Enviar los siguientes datos al correo: notificaciones@thbmexico.com</h1>
                      <ul style="color:black; font-size: 15px; text-align:left;">
                          <li>» Nombre Completo</li>
                          <li>» Identificación Oficial Vigente Escaneada por ambos lados.</li>
                          <li>» Rfc</li>
                          <li>» Homoclave</li>
                      </ul>
                    </div>

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="Boolean($v.formData.occupation.$model)"
                        :text="'¿Cuál es tu Ocupación?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">

                        <v-select
                          v-model.trim="$v.formData.occupation.$model"
                          :options="occupations"
                          index="id"
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >
                        </v-select>
                        
                        <div
                          class="t-form-error"
                          v-if="!$v.formData.occupation.$model && showErrors"
                        >
                          La ocupación es obligatoria.
                        </div>
                      </div>
                    </div>

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="Boolean($v.formData.nationality.$model)"
                        :text="'¿Cuál es tu Nacionalidad?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">
                        <v-select
                          v-model.trim="$v.formData.nationality.$model"
                          :options="nationalities"
                          index="id"
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >
                        </v-select>
                        
                        <div
                          class="t-form-error"
                          v-if="!$v.formData.nationality.$model && showErrors"
                        >
                          La nacionalidad es obligatoria.
                        </div>
                      </div>
                    </div>

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="
                          Boolean($v.formData.birth_country.$model)
                        "
                        :text="'¿Cual es tu país de Nacimiento?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">

                        <v-select
                          ref="focusForm"
                          v-model.trim="$v.formData.birth_country.$model"
                          :options="countries"

                          index="id"
                          return-object
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >

                        </v-select>

                        <div
                          class="t-form-error"
                          v-if="!$v.formData.birth_country.$model && showErrors"
                        >
                          El país de nacimiento es obligatorio.
                        </div>
                      </div>
                    </div>

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="
                          Boolean($v.formData.fiscal_regime.$model)
                        "
                        :text="'¿Cual es tu Régimen Fiscal?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">

                        <v-select
                          ref="focusForm"
                          v-model.trim="$v.formData.fiscal_regime.$model"
                          :options="list_regime_fiscal"

                          index="id"
                          return-object
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >
                        </v-select>

                        <div
                          class="t-form-error"
                          v-if="!$v.formData.fiscal_regime.$model && showErrors"
                        >
                          El Regimen Fiscal es obligatorio.
                        </div>
                      </div>
                    </div>

                    <div class="t-input-group">
                      <t-label
                        class="t-label"
                        :showStatus="showErrors"
                        :valid="
                          Boolean($v.formData.CFDI_use.$model)
                        "
                        :text="'¿Cual es tu Uso de CFDI?'"
                        :required="true"
                      />
                      <div class="flex-1 max-w-full">

                        <v-select
                          ref="focusForm"

                          v-model.trim="$v.formData.CFDI_use.$model"
                          :options="list_cfdi_use"

                          index="id"
                          return-object
                          label="name"
                          class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                        >
                        </v-select>

                        <div
                          class="t-form-error"
                          v-if="!$v.formData.CFDI_use.$model && showErrors"
                        >
                          El uso de CFDI es obligatorio.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Mensaje de aviso No requiere factura-->
                  <div v-else>
                    <div class="w-full my-5 text-2xl text-center text-primary">
                      <h1 style="text-transform:uppercase;">Datos de Facturación</h1>
                      <h1 style="font-size:15px;" class="">Estimado cliente, te recordamos que en caso de que no desees factura, 
                        tu póliza se facturará a público en general y no podrá ser solicitada con posterioridad</h1>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>







<!-- 

          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Facturación</h2>
                <div class="t-section-content py-5">

                  <div class="t-input-group">
                    <label for="name" class="t-label">¿Tipo de Persona?</label>
                    <div class="flex-1 relative">
                      <v-select
                        value = "'Persona Moral'"
                        type="text"
                        class="t-form h-max-10"
                        v-model="userData.user_address[0].user.fiscal_regime"

                        :options="['Persona Moral','Persona Física']"
                        index="id"
                        label="name"
                        return-object
                      >

                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->


          <div class="w-full flex flex-wrap justify-end">
            <div
              v-if="showErrors && $v.formData.$invalid"
              
              class="
                w-full
                rounded-md
                px-5
                py-2
                border-2 border-red-700
                bg-red-50
                text-red-700
                my-3
              "
            >
              <font-awesome-icon
                :icon="['fas', 'exclamation-triangle']"
                class="fa-lg"
              />
              Algunos campos requieren de tu atención antes de poder continuar.
            </div>
            <button
              type="submit"
              class="
                bg-special-3
                h-10
                md:px-10
                rounded-md
                text-primary text-xl md:text-2xl
                w-full
                sm:w-auto
              "
            >
              Actualizar Información
            </button>
          </div>
        </form>
      </div>
      <div class="w-full flex justify-center absolute bottom-0" style="height: 0px">
        <div class="container mx-auto z-0" style="box-shadow: rgb(255 255 255) 0px 0px 150px 75px;">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isRfcSameAsFeNac } from "../constants/config";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  sameAs,
  helpers,
} 
from "vuelidate/lib/validators";
import { scrollTo } from "../utils/index";
import Loader from "../components/Loader.vue"
import axios from "axios";
import _ from "lodash";
import { VueRecaptcha } from 'vue-recaptcha';
import { siKey } from "../constants/config"

const hasSymbol = helpers.regex(
  "hasSymbol",
  /^.*[-!$%^&*()_+|~=`{}[\]:";'<>?,./]+.*/
);
const rfc = helpers.regex(
  "rfc",
  /^([A-ZÑ&]{3,4})(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))/
);
// import FormGenerator from '../components/FormsGenerator/FormGenerator'
const touchMap = new WeakMap();

export default {
  data: function () {
    return {
      siteKey: siKey,
      showLoader: false,
      showErrors: false,
      formTypeShowed: {
        id: 1,
        name: "Crear cuenta",
      },
      formTypeShowedOptions: [
        {
          id: 0,
          name: "Iniciar sesión",
        },
        {
          id: 1,
          name: "Crear cuenta",
        },
      ],

      //? Billing Data
      require_billing: "",
      occupation: "",
      nationality: "",
      birth_country: "",
      fiscal_regime: "",
      CFDI_use: "",
      selectedCountries:"",
      selectedCFDI:"",
      
      countries: [],
      selectedOccupation: "",
      selectedNationalities:"",
      occupations: [],
      nationalities:[],
      list_regime_fiscal:[],
      list_cfdi_use:[],

      formData: {
        /* Data for to create new user */
        site: 3,
        email: "",
        email_c: "",
        password: "",
        password_c: "",
        recaptchaToken: "",
        v: 0,

        /* Extra user data*/
        name: "",
        first_name: "",
        last_name: "",
        gender: { id: null, name: "Selecciona un valor" },
        civil_status: { id: null, name: "Selecciona un valor" },
        rfc: "",
        homoclave: "",
        born_date: "",
        phone_number: "",


        // Billing Data
        require_billing: "",
        occupation: "",
        nationality: "",
        fiscal_regime: "",
        birth_country: "",
        CFDI_use: "",


        /* Extra user data for address*/
        country: { id: 1, name: "México" },
        zip_code: { id: null, name: "Selecciona un valor" },
        state: { id: null, name: "Selecciona un valor" },
        town_council: { id: null, name: "Selecciona un valor" },
        colony: { id: null, name: "Selecciona un valor" },
        street: "",
        num_ext: "",
        num_int: "",
        address_id: null,
      },
      formDataOptions: {
        gender: [],
        civil_status: [],
        zip_codes: [],
      },
      lastQuoteDataLocal: {
        requeriments: [
          {
            id: 29,
            value: "kiktro@gmail.com",
          },
          {
            id: 1,
            value: "Irvin",
          },
          {
            id: 2,
            value: new Date(),
          },
          {
            id: 4,
            value: "1",
          },
          {
            id: 5,
            value: "0",
          },
          {
            id: 6,
            value: "1",
          },
          {
            id: 7,
            value: {
              id: 207492,
              zip_code: "54473",
              name: "54473-CAMPESTRE LIBERACIÓN",
            },
          },
          {
            id: 8,
            value: "Fresnos 40",
          },
          {
            id: 30,
            value: "1",
          },
          {
            id: 31,
            value: "1000000",
          },
          {
            id: 32,
            value: "200000",
          },
          {
            id: 22,
            value: "30",
          },
        ],
        categories: [8],
      },

    };
  },
  components: {
    "loader": Loader,
    'vue-recaptcha': VueRecaptcha
  },
  validations() {

    let validations = {

      formData: {

        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        first_name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        last_name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50),
        },
        gender: {
          id: {
            required,
          },
        },
        civil_status: {
          id: {
            required,
          },
        },
        rfc: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          rfc,
          isRFCValid:(value) => {

            var rfc = value.toUpperCase()
            var name = this.formData.name.toUpperCase()
            var first_name = this.formData.first_name.toUpperCase()
            var last_name = this.formData.last_name.toUpperCase()
            var born_date = this.formData.born_date

            if(rfc.length == 10){
              var rfc_first = first_name.substring(0,1)
              var rfc_second = () => {
                  var vowels = ["A" , "E" , "I" , "O" , "U"]
                  var rfc_second = ""
                  var rfc_second_found = false
                  for (let i = 0; i < first_name.length; i++) {
                      var char = first_name.charAt(i)
                      if(!rfc_second && vowels.indexOf(char)!= -1){
                          // First Vowel Found
                          rfc_second = char
                          rfc_second_found = true
                      }
                  }
                  return rfc_second
              }
              var rfc_third = last_name.substring(0,1)
              var rfc_fourth = name.substring(0,1)

              var rfc_year = born_date.substring(2,4)
              var rfc_month = born_date.substring(5,7)
              var rfc_day = born_date.substring(8,10)

              var rfc_build = `${rfc_first}${rfc_second()}${rfc_third}${rfc_fourth}${rfc_year}${rfc_month}${rfc_day}`

              if(rfc_build == rfc){
                  return true
              } else {
                  return false
              }

            } else {
              return false
            }

          }
        },
        homoclave: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(3),
        },
        born_date: {
          required,
          maxValue: (value) => value < new Date().toISOString(),
        },
        phone_number: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(20),
        },
        street: {
          maxLength: maxLength(250),
        },
        num_ext: {},
        num_int: {},


        //? Billing Data
        require_billing : {},
        occupation : {
        required: requiredIf(function () {
          return this.formData.require_billing == 'Sí'
        })
        }
        ,

        nationality : {
        required: requiredIf(function () {
          return this.formData.require_billing == 'Sí'
        })
    
        },
        birth_country : {
          required: requiredIf(function () {
          return this.formData.require_billing == 'Sí'
        })
        },
        fiscal_regime : {
          required: requiredIf(function () {
          return this.formData.require_billing == 'Sí'
        })
        },
        CFDI_use : {
          required: requiredIf(function () {
          return this.formData.require_billing == 'Sí'
        })
        },


      },
    }

    if (this.formTypeShowed.id == 1 && !this.loggedIn) {
      /* Account create */
      validations.formData["email"] = {
        required,
        email,
      };
      validations.formData["email_c"] = {
        required,
        sameAsEmail: sameAs("email"),
      };
      validations.formData["password"] = {
        required,
        hasSymbol,
        minLength: minLength(5),
      };
      validations.formData["password_c"] = {
        required,
        sameAsPassword: sameAs("password"),
      };
    }

    return validations;
  },
  props: [],
  computed: {
    ...mapGetters([
      "lastQuoteData",
      "user",
      "userData",
      "loggedIn",
      "userData",
    ]),
  },
  mounted() {
    let self = this;
    this.lastQuoteDataLocal = this.lastQuoteData;
    self.setInitialState();
    setTimeout(() => {
      self.$v.$reset();
    }, 5000);


    // Fiscal Catalogs
    this.$http
    .get(this.$APIEndpoint + this.$APIServices.catalog_fiscal)
    .then((response) => {

      var catalogs = response.data
      catalogs.forEach(catalog => {

        var catalog_name = catalog["name"]
        var catalog_json = catalog["json"]

        switch(catalog_name){
          
          case "Ocupaciones":
            catalog_json.forEach(catalog_json_object => {
              // var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.occupations.push(value)
            });
          break;
          case "Nacionalidades":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.nationalities.push(`(${value}) ${name}`)           
            });
          break;
          case "Países":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.countries.push(`(${value}) ${name}`)
            });
          break;
          case "Regimenes_Fiscales":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_regime_fiscal.push(`(${value}) ${name}`)
            });
          break;
          case "Uso_CFDI":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_cfdi_use.push(`(${value}) ${name}`)
            });
          break;
        }


      });

    });

  },
  methods: {
    ...mapActions([
      "getZipCode",
      "signIn",
      "getToken",
      "getUserDetail",
      "getGender",
      "getCivilStatus",
      "updateOrCreateUser",
      "updateOrCreateUserAddress",
    ]),

    ...mapMutations(["setIsUserSideBarOpen"]),

    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.getElementById("scroll-wraper-content");
        let targetEl = document.querySelector(".t-form-error:first-of-type");

        //console.log("targetEl", targetEl);
        scrollTo(duration, parentEl, targetEl);
      }, 100);
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    async setInitialState() {
      let self = this;
      self.showLoader = true;
      let zip_code = this.getZipCodeOrNone(this.lastQuoteData.requeriments);
      // console.log("zip_code: ", zip_code);
      if (zip_code != null) {
        // console.log("Zipcode dif de null");
        let payload = {
          params: {
            full_data: true,
          },
          id: zip_code.value.id,
        };
        await this.getZipCode(payload).then((response) => {
          self.fillDefaultDataForm(response);
        });
      } else {
        self.fillDefaultDataForm(null);
      }
      this.getGender().then((response) => {
        self.formDataOptions.gender = [
          { id: null, name: "Selecciona un valor" },
          ...response,
        ];
      });
      this.getCivilStatus().then((response) => {
        self.formDataOptions.civil_status = [
          { id: null, name: "Selecciona un valor" },
          ...response,
        ];
      });

      if (self.loggedIn) {
        self.getUserDetail().then(() => {
          self.showLoader = false;
        });
      } else {
        self.showLoader = false;
      }
    },
    getZipCodeOrNone(data) {
      // console.log("data: ", data);
      if(data != undefined){
        let reqFinded = data.filter((item) => item.id == 7);
        if (reqFinded.length) {
          return reqFinded[0];
        } else {
          return null;
        }
      } else {
        return null
      }
    },
    calculateUserData(newUserData) {
      this.formData.email = newUserData.email;
      this.formData.name = newUserData.name
        ? newUserData.name
        : this.formData.name;
      this.formData.first_name = newUserData.first_name
        ? newUserData.first_name
        : this.formData.first_name;
      this.formData.last_name = newUserData.last_name
        ? newUserData.last_name
        : this.formData.last_name;
      this.formData.rfc = newUserData.rfc ? newUserData.rfc : this.formData.rfc;
      this.formData.homoclave = newUserData.homoclave
        ? newUserData.homoclave
        : this.formData.homoclave;
      this.formData.born_date = this.formData.born_date
        ? this.formData.born_date
        : newUserData.born_date;

      this.formData.phone_number = this.formData.phone_number
        ? this.formData.phone_number
        : newUserData.phone_number;


      this.formData.gender = newUserData.gender
        ? newUserData.gender
        : this.formData.gender;
      this.formData.civil_status = newUserData.civil_status
        ? newUserData.civil_status
        : this.formData.civil_status;
      if (newUserData.user_address.length) {
        let address = newUserData.user_address[0];
        this.formData.country = address.country;
        this.formData.zip_code = {
          ...address.zip_code,
          name: address.zip_code.zip_code,
        };
        this.formData.state = address.state;
        this.formData.town_council = address.town_council;
        this.formData.colony = address.colony;
        this.formData.street = this.formData.street
          ? this.formData.street
          : address.street;
        this.formData.num_int = this.formData.num_int
          ? this.formData.num_int
          : address.num_int;
        this.formData.num_ext = this.formData.num_ext
          ? this.formData.num_ext
          : address.num_ext;
        this.formData.address_id = address.id;


        //Billing Data

        this.formData.fiscal_regime = this.formData.fiscal_regime
          ? this.formData.fiscal_regime
          : newUserData.fiscal_regime;


        this.formData.birth_country = this.formData.birth_country
          ? this.formData.birth_country
          : newUserData.birth_country;

        this.formData.require_billing = this.formData.require_billing
          ? this.formData.require_billing
          : newUserData.require_billing;

        this.formData.occupation = this.formData.occupation
          ? this.formData.occupation
          : newUserData.occupation;

        this.formData.nationality = this.formData.nationality
          ? this.formData.nationality
          : newUserData.nationality;

        this.formData.CFDI_use = this.formData.CFDI_use
          ? this.formData.CFDI_use
          : newUserData.CFDI_use;

      }
    },
    fillDefaultDataForm(locationData) {
      // Knowed data
      // console.log("locationData:", locationData);
      // console.log("locationData:", JSON.stringify(locationData));

      if(locationData != null){

        try {
        let dateString = this.formatDate(
          this.lastQuoteDataLocal.requeriments.filter((item) => item.id == 2)[0]
            .value
        );
        this.formData.born_date = dateString;
        } catch (error) {
        this.formData.born_date = this.formatDate(String(new Date()));
        }

        try {
        let fullName = this.lastQuoteDataLocal.requeriments.filter(
          (item) => item.id == 1
        )[0].value;
        this.formData.name = fullName[0];
        this.formData.first_name = fullName[1];
        this.formData.last_name = fullName[2];
        } catch (error) {
        this.formData.name = "";
        }

        this.formData.email = this.lastQuoteDataLocal.requeriments.filter(
        (item) => item.id == 29
        )[0].value;

        try {
        this.formData.country = locationData.country;
        this.formData.zip_code = {
          ...locationData.zip_code,
          name: locationData.zip_code ? locationData.zip_code.name : "",
        };
        }catch (error) {
        this.formData.country = null;        
        }

        this.formData.state = locationData.state;
        this.formData.town_council = locationData.town_council;
        this.formData.colony = locationData.colony;

        try {
        let auxStreet = this.lastQuoteDataLocal.requeriments.filter(
          (item) => item.id == 8
        )[0].value;
        this.formData.street = auxStreet[0];
        this.formData.num_ext = auxStreet[1];
        this.formData.num_int = "";
        } catch (error) {
        this.formData.street = "";
        this.formData.num_ext = "";
        this.formData.num_int = "";
        }


      }

      // Unknowed data
      // this.formData.first_name = locationData.
      // this.formData.last_name = locationData.
      // this.formData.gender = locationData.
      // this.formData.civil_status = locationData.
      // this.formData.rfc = locationData.
      // this.formData.homoclave = locationData.
    },
    setZipCodeOptions(data) {
      this.formDataOptions.zip_codes = data;
    },
    formatDate(date) {
      if (typeof date == "string") {
        try {
          date = new Date(date);
        } catch (error) {
          date = new Date();
        }
      }
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = ("0" + day).slice(-2);
      month = ("0" + month).slice(-2);
      return `${year}-${month}-${day}`;
    },
    onSearchCP(search, loading) {
      if (search.length) {
        loading(true);
        this.search(
          loading,
          search,
          this,
          this.api_resource,
          this.httpConnector
        );
      }
    },
    search: _.debounce((loading, search, vm, api_resource, httpConnector) => {
      let url =
        vm.$APIEndpoint + vm.$APIServices.zip_code + "?value=" + String(search);
      let http = httpConnector ? httpConnector : axios;
      http.get(url).then((res) => {
        vm.setZipCodeOptions(res.data);
        loading(false);
      });
    }, 350),
    async refreshAddressInfo() {
      let self = this;
      let payload = {
        params: {
          full_data: true,
        },
        id: self.formData.zip_code.id,
      };
      await self.getZipCode(payload).then((response) => {
        self.fillDefaultDataForm(response);
      });
    },
    onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onVerify(recaptchaToken){
      let self = this;
      self.$v.$touch();
      this.showLoader = true

      self.formData["recaptchaToken"] = recaptchaToken
      self.formData["v"] = 1
      
      self.$refs.recaptcha.reset();

      if (self.$v.$invalid) {
        self.showErrors = true;
        self.showLoader = false;
        self.scrollToFirstError();
      } else {
          self.showErrors = false;

          const path = this.$APIEndpoint + this.$APIServices.get_info_users

          await this.$http.get(path)
          .then((response) => {
              // console.log(response)
              // console.log(response.data)
              // const user_id = this.userData.id
              // console.log(this.userData.id)
              var response_data = response.data
              var rfcs_array = []

              for (let i = 0; i < response_data.length; i++) {
                rfcs_array.push(response_data[i]['rfc'])
              }
              var idUserFound = this.userData.id

              if(rfcs_array.indexOf(self.formData.rfc.toString()) != -1){
                idUserFound = response_data[rfcs_array.indexOf(self.formData.rfc.toString())].id
                // console.log(idUserFound)
                // console.log(rfcs_array.indexOf(self.formData.rfc.toString()))
              }

              if( rfcs_array.indexOf(self.formData.rfc.toString()) == -1 || idUserFound == this.userData.id){
                this.UpdateUser()
              } else {
                this.showLoader = false
                self.friendly_error = "El RFC ya existe en la Base de Datos Registrado con otro Usuario."
                self.showLoader = false;
                self.$notify({
                  group: "foo",
                  title: "Error en registro",
                  text: self.friendly_error,
                  type: "error",
                  duration: 10000,
                });
              }
          })
          .catch((error)=> {
              // console.log(error)
              this.showLoader = false
              throw error;
          }).finally(()=> { this.showLoader = false} )

          }
    },
    onExpired(){
      this.$refs.recaptcha.reset();
    },
    async UpdateUser(){
      let self = this

      self.$v.$touch();
      self.showLoader = true;
      if (self.$v.$invalid) {
        self.showErrors = true;
        self.showLoader = false;
        self.scrollToFirstError();
      } else {
        self.showErrors = false;
        if (this.formTypeShowed.id == 1) {
          if (self.formData.email != self.formData.email) {
            return false;
          }
        }
        let userPayload = {
          id: self.user ? self.user.id : null,
          data: {
            email: self.formData.email,
            name: self.formData.name,
            first_name: self.formData.first_name,
            last_name: self.formData.last_name,
            rfc: self.formData.rfc,
            homoclave: self.formData.homoclave,
            born_date: self.userData.born_date,
            phone_number: self.formData.phone_number,
            gender: self.formData.gender.id,
            civil_status: self.formData.civil_status.id,
            recaptchaToken: self.formData.recaptchaToken,
            v: 0,

            //Billing Data
            require_billing: self.formData.require_billing,
            occupation: self.formData.occupation,
            nationality: self.formData.nationality,
            birth_country: self.formData.birth_country,
            fiscal_regime: self.formData.fiscal_regime,
            CFDI_use: self.formData.CFDI_use
          },
        };
        let userCreatePayload = {
          id: null,
          data: {
            site: self.formData.site,
            email: self.formData.email,
            email_c: self.formData.email,
            password: self.formData.password,
            password_c: self.formData.password_c,
            recaptchaToken: self.formData.recaptchaToken,
            v: 0,            
          },
        };

        let userAddressPayload = JSON.parse(
          JSON.stringify({
            id: self.formData.address_id ? self.formData.address_id : null,
            data: {
              country: self.formData.country ? self.formData.country.id : null,
              zip_code: self.formData.zip_code
                ? self.formData.zip_code.id
                : null,
              state: self.formData.state ? self.formData.state.id : null,
              town_council: self.formData.town_council
                ? self.formData.town_council.id
                : null,
              colony: self.formData.colony ? self.formData.colony.id : null,
              street: self.formData.street,
              num_ext: self.formData.num_ext,
              num_int: self.formData.num_int,
            },
          })
        );

        if (!self.loggedIn) {
          //let createUserResponse = await self
          //Indicador para validación recaptcha
          userCreatePayload.data["v"] = 1          
          await self
            .signIn(userCreatePayload)
            .catch((error) => {
              let errors = error.response.data.non_field_errors;
              self.$notify({
                group: "foo",
                title: "Error en registro",
                text: errors[0],
                duration: 10000,
              });
            });

          //Indicador para validación recaptcha
          userCreatePayload.data["v"] = 2            
          await self
            .getToken(userCreatePayload.data)
            .then(() => {
              // self.getUserDetail();
              // if (localStorage.getItem("user")) {
              //   self.$parent.$refs["usersidebar"].setSidebarStatus(3)
              // } else {
              //   self.$parent.$refs["usersidebar"].setSidebarStatus(1)
              // }
              userPayload.id = self.user.id;
            })
            .catch((e) => {
              self.$notify({
                group: "foo",
                title: "Error en registro",
                text: e.response.data.error,
                duration: 10000,
              });
            });

            //Indicador de validación recaptcha
            userPayload.data["v"] = 2            
        }
        else {
          //Indicador de validación recaptcha
          userPayload.data["v"] = 1
        }
        
        let errors = [];
        //let userResponse = await self
        // console.log("BeforeUpdateOrCreateUser")
        // console.log(userPayload)

        await self
          .updateOrCreateUser(userPayload)
          .catch((error) => {
            // console.log(error);
            errors.push(error.response.data);
          });

        await self
          .updateOrCreateUserAddress(userAddressPayload)
          .then(() => self.getUserDetail())
          .catch((error) => {
            errors.push(error.response.data);
          });
        if (errors.length) {
          errors.forEach((error) => {
            self.$notify({
              group: "foo",
              title: error.title,
              text: `<b>${error.code}</b>: ${error.detail}`,
              duration: 3000,
            });
          });
        }
        self.showLoader = false;
        self.$notify({
            group: "foo",
            title: "Actualizado de Datos Personales Exitoso",
            text: `<b>Tus Datos Fueron Actualizados Correctamente</b>`,
            type: "success",
            duration: 3000,
          });

      }
      return false;


    }

  },
  watch: {
    userData: function (newVal) {
      this.calculateUserData(newVal);
    },
  },
};
</script>

<style >
[disabled] {
  opacity: 0.7;
}
</style>